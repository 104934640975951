import React from 'react';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/main.scss';
import HeroLayout from '../../components/Layouts/HeroLayout';
import Works from '../../components/Works/Works';
export default () => {
  return (
    <HeroLayout metaTitle="Works" metaDescription='All of my works in one place'>
      <Works />
    </HeroLayout>
  );
};
