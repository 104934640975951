import React from 'react';

const LoadingBlock = () => {
  return (
    <div className="loading-block">
      <div className="loading__square"></div>
      <div className="loading__square"></div>
      <div className="loading__square"></div>
      <div className="loading__square"></div>
      <div className="loading__square"></div>
      <div className="loading__square"></div>
      <div className="loading__square"></div>
    </div>
  );
};

export default LoadingBlock;
