import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import PortfolioContext from '../../context/context';
import ScrollLine from '../ScrollLine';
import LoadingBlock from '../LoadingBlock';

const WorksHero = (props) => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="blog" className="jumbotron">
      <Container className="px-0">
        <Fade top duration={1000} delay={1000} distance="30px">
          <div className="pb-4 pl-2 pb-md-5">
            <LoadingBlock />
          </div>
        </Fade>

        <h1 className="blog-title" data-splitting>
          Collection of all my works
        </h1>

        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
          <p className="blog-sub-title ">Experimental, functional and fun</p>
        </Fade>

        <div className="pt-5">
          <ScrollLine />
        </div>
      </Container>
    </section>
  );
};

export default WorksHero;
