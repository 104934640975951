import React, { useEffect, useState } from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';
import Img from 'gatsby-image';

const WorksGradientCard = ({
  to,
  img,
  gradientStyle,
  title,
  subtitle,
  alt,
  exit,
  entry,
  isDesign,
  url
}) => {
  if (isDesign) {
    return (
      <a href={url} target="_blank" rel="noopener norefferer">
        <div
          className="works-new-card"
          style={{
            backgroundImage: `${gradientStyle}`,
          }}
        >
          <div className="centered-media">
            <Img fluid={img} fadeIn alt="tech-work" />
          </div>
          <div className="card-content-container">
            <div className="card-text">
              <h5>{title}</h5>
              <p>{subtitle}</p>
            </div>

            <div className={[isDesign ? 'card-actions' : 'd-none']}>
              <svg
                id="arrow-top-right"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 13.051 13.01"
              >
                <path
                  id="arrow-top-right-2"
                  data-name="arrow-top-right"
                  d="M10.525,5.495l0,2,4.558.011-9.6,9.584,1.413,1.416,9.63-9.61-.011,4.614,2,0,.019-8Z"
                  transform="translate(-5.475 -5.495)"
                  fill="#171717"
                />
              </svg>
            </div>
          </div>
        </div>
      </a>
    );
  }
  return (
    <TransitionLink className="w-100" to={to} exit={exit} entry={entry}>
      <div
        className="works-new-card"
        style={{
          backgroundImage: `${gradientStyle}`,
        }}
      >
        <div className="centered-media">
          <Img fluid={img} fadeIn alt="tech-work" />
        </div>
        <div className="card-content-container">
          <div className="card-text">
            <h5>{title}</h5>
            <p>{subtitle}</p>
          </div>

          <div className={[isDesign ? 'card-actions' : 'd-none']}>
            <svg
              width={24}
              height={24}
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 16H27"
                stroke="black"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 7L27 16L18 25"
                stroke="black"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </TransitionLink>
  );
};

export default WorksGradientCard;
