import React, { useContext, useEffect, useState, useRef } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Splitting from 'splitting';
import gsap from 'gsap';
import { TransitionPortal } from 'gatsby-plugin-transition-link';
import { useStaticQuery, graphql } from 'gatsby';
import WorksGradientCard from '../WorksGradientCard';

const WorksGrid = (props) => {
  const { blogs } = useContext(PortfolioContext);
  const sectionRef = useRef(null);
  const cardsRef = useRef([]);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const layoutContents = useRef();
  const transitionCover = useRef();
 
  useEffect(() => {
    Splitting({
      target: '[data-splitting]',
      by: 'chars',
      key: null,
    });
  }, []);

  const data = useStaticQuery(graphql`
    query image {
      allFile(
        sort: { order: ASC, fields: name }
        filter: { absolutePath: { regex: "/work-images/" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  // Animation for fading in
  const fadeIn = (element) => {
    gsap.to(element, 1, {
      opacity: 1,
      y: 10,
      ease: 'power4.out',
      stagger: {
        amount: 1,
      },
    });
  };
  // Animation for fading out
  const fadeOut = (element) => {
    gsap.to(element, 1, {
      opacity: 0,
      y: -20,
      ease: 'power4.out',
      stagger: {
        amount: 1,
      },
    });
  };

  const exitHorizontal = ({ length }, direction) => {
    const seconds = length;

    const directionTo = direction === 'left' ? '-100%' : '100%';
    const directionFrom = direction === 'left' ? '100%' : '-100%';

    return gsap
      .timeline()
      .set(transitionCover.current, { x: directionFrom, display: 'block' })
      .to(transitionCover.current, {
        x: '0%',
        ease: 'power1.easeInOut',
        duration: seconds / 2,
      })
      .set(layoutContents.current, { opacity: 0 })
      .to(transitionCover.current, {
        x: directionTo,
        ease: 'power1.easeInOut',
        duration: seconds / 2,
      });
  };

  return (
    <section id="works-grid-wrapper" ref={sectionRef}>
      <Container className="px-0">
        <div className="grid-content">
          <div className="works-new-grid mt-0">
            {blogs.map((project, index) => {
              const { title, id, to, subtitle, gradient, isDesign, url } = project;
              return (
                <Fade
                  delay={index % 2 === 0 ? 500 : 800}
                  bottom
                  duration={300}
                  distance="30px"
                  key={id}
                >
                  <WorksGradientCard
                    title={title}
                    subtitle={subtitle}
                    url={url}
                    img={data?.allFile?.edges[index]?.node?.childImageSharp?.fluid}
                    gradientStyle={gradient}
                    isDesign={isDesign}
                    to={to}
                    exit={{
                      length: 1.0,
                      trigger: ({ exit }) => exitHorizontal(exit, 'left'),
                    }}
                    entry={{
                      delay: 0.5,
                    }}
                  />
                </Fade>
              );
            })}
          </div>
        </div>
      </Container>
      <TransitionPortal>
        <div
          ref={transitionCover}
          style={{
            position: 'fixed',
            background: '#131313',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'none',
          }}
        />
      </TransitionPortal>
    </section>
  );
};

export default WorksGrid;
