import React, { useState, useEffect } from 'react';

import { PortfolioProvider } from '../../context/context';

import {
  heroData,
  aboutData,
  projectsData,
  contactData,
  footerData,
  allWorks,
} from '../../mock/data';
import Footer from '../Footer/Footer';
import { Scrollbars } from 'react-custom-scrollbars';
import WorksHero from '../WorksHero';
import WorksGrid from '../WorksGrid';
import CursorComponent from '../CursorComponent';

function Works(props) {
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setBlogs([...allWorks]);
    setContact({ ...contactData });
    setFooter({ ...footerData });
  }, []);

  return (
    <Scrollbars
      style={{ height: '100vh', width: '100%', overflow: 'hidden scroll' }}
     
    >
      <PortfolioProvider value={{ hero, about, projects, contact, blogs, footer }}>
        <WorksHero />
        <WorksGrid />
        <Footer />
        <CursorComponent />
      </PortfolioProvider>
    </Scrollbars>
  );
}

export default Works;
